<template>
  <commomNav :active="0" :columnList="columnList"></commomNav>
  <div class="carousel">
    <el-carousel
      :interval="5000"
      trigger="click"
      :height="bannerHeight + 'px'"
      v-if="carouselList.length"
    >
      <el-carousel-item v-for="item in carouselList" :key="item">
        <img :src="item.imagePath" />
      </el-carousel-item>
    </el-carousel>
  </div>
  <div class="gift-photo">
    <div class="gift_container" v-if="language == 'zh'">
      <div class="left">
        <div class="gift-text">创鑫互联</div>
        <div class="line"></div>
        <div class="gift-text2">
          基于数字化、智慧化、云计算技术，为移动互联、支付系统、智慧医疗
          领域提供服务。
        </div>
        <el-button type="warning" class="button1">
          <router-link to="/consultnow" style="text-decoration: none"
            >立即咨询</router-link
          >
        </el-button>
        <el-button type="primary" class="button2">
          <router-link to="/settlement" style="text-decoration: none"
            >申请入驻</router-link
          >
        </el-button>
      </div>
      <div class="right">
        <div class="payData">
          <div class="num">
            <div>{{ media }}</div>
            <div class="unit">万</div>
          </div>
          <div class="text">支付数据</div>
        </div>
        <div class="line"></div>
        <div class="partner">
          <div class="num">
            <div>300</div>
            <div class="unit">+</div>
          </div>
          <div class="text">合作伙伴</div>
        </div>
      </div>
    </div>
    <div class="gift_container" v-if="language == 'en'">
      <div class="left">
        <div class="gift-text">CXHL</div>
        <div class="line"></div>
        <div class="gift-text2">
          Based on digital, intelligent and cloud computing technologies, it
          provides services for mobile Internet, payment system and smart
          medical care Provide services in this field.
        </div>
        <el-button type="warning" class="button1">
          <router-link to="/consultnow" style="text-decoration: none"
            >Consult Now</router-link
          >
        </el-button>
        <el-button type="primary" class="button2">
          <router-link to="/settlement" style="text-decoration: none"
            >Application</router-link
          >
        </el-button>
      </div>
      <div class="right">
        <div class="payData">
          <div class="num">
            <div>{{ media }}</div>
            <div class="unit">Thousand</div>
          </div>
          <div class="text">Payment Data</div>
        </div>
        <div class="line"></div>
        <div class="partner">
          <div class="num">
            <div>300</div>
            <div class="unit">+</div>
          </div>
          <div class="text">Partner</div>
        </div>
      </div>
    </div>
  </div>
  <div class="solution">
    <div class="title">{{ solutionName }}</div>
    <div class="tab">
      <span
        v-for="(item, index) in solutionList"
        :key="index"
        @click="toMedical(item.id)"
        >{{ item.subjectName }}</span
      >
    </div>
    <div class="solution_content" v-if="this.screenWidth > 1440">
      <div
        class="case_contain"
        v-for="(item, index) in solutionList"
        :key="index"
        @click="toMedical(item.id)"
      >
        <div class="image"><img :src="item.imgUrl" /></div>
        <div class="title">
          <div>{{ item.subjectName }}</div>
          <div class="arrow">
            <img
              class="normal"
              src="@/assets/img/home/箭头_向右-正常状态.png"
            />
            <img class="hover" src="@/assets/img/home/箭头_向右-选中.png" />
          </div>
        </div>
        <div class="content">{{ item.briefIntroduction }}</div>
      </div>
    </div>
    <el-carousel
      :interval="5000"
      type="card"
      height="470px"
      v-if="this.screenWidth < 1440 && solutionList.length"
    >
      <el-carousel-item
        v-for="(item, index) in solutionList"
        :key="index"
        @click="toMedical(item.id)"
      >
        <div class="case_contain">
          <div class="image"><img :src="item.imgUrl" /></div>
          <div class="title">
            <div>{{ item.subjectName }}</div>
            <div class="arrow">
              <img
                class="normal"
                src="@/assets/img/home/箭头_向右-正常状态.png"
              />
              <img class="hover" src="@/assets/img/home/箭头_向右-选中.png" />
            </div>
          </div>
          <div class="content">{{ item.briefIntroduction }}</div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>

  <div class="Hardware">
    <div class="title">
      {{ HardwareName }}
      <div class="more">
        <span
          class="text-group_2"
          v-if="language == 'zh'"
          @click="toPage('HardwareList')"
          >更多产品</span
        >
        <span
          class="text-group_2"
          v-if="language == 'en'"
          @click="toPage('HardwareList')"
          >More</span
        >
        <img class="normal" src="@/assets/img/home/箭头_向右-正常状态.png" />
      </div>
    </div>
    <div class="Hardware_container">
      <template v-for="(item, index) in HardwareList" :key="index">
        <div
          class="Hardware_content"
          v-if="index < 6"
          @click="toPage('Hardware', item.id)"
        >
          <div class="image"><img :src="item.imgUrl" /></div>
          <div class="name">{{ item.subjectName }}</div>
          <div class="desc">{{ item.briefIntroduction }}</div>
          <div class="btn">
            <img
              class="normal"
              src="@/assets/img/home/箭头_向右-正常状态.png"
            />
            <img class="hover" src="@/assets/img/home/箭头_向右-选中(1).png" />
          </div>
        </div>
      </template>
    </div>
  </div>
  <div class="software">
    <div class="software_container">
      <div class="title">{{ softwareName }}</div>
      <div class="carousel">
        <el-carousel
          :interval="5000"
          type="card"
          height="400px"
          v-if="softwareList.length"
        >
          <el-carousel-item
            v-for="(item, index) in softwareList"
            :key="index"
            @click="toPage('Hardware', item.id)"
          >
            <div class="carousel_box">
              <div class="title">{{ item.subjectName }}</div>
              <div class="normal_content">
                <div class="desc">{{ item.briefIntroduction }}</div>
                <div class="image">
                  <div class="img"><img :src="item.imgUrl" /></div>
                </div>
              </div>
              <div class="hover_content">
                <div class="image">
                  <div class="img"><img :src="item.imgUrl" /></div>
                </div>
                <div class="desc">{{ item.briefIntroduction }}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
  <!-- <div class="classical_case">
    <div class="title">
      {{ classicalName }}
      <div class="more" @click="toPage('Case')">
        <span class="text-group_2" v-if="language == 'zh'">查看更多</span>
        <span class="text-group_2" v-if="language == 'en'">More</span>
        <img class="normal" src="@/assets/img/home/箭头_向右-正常状态.png" />
      </div>
    </div>
    <div class="classical_content">
      <div
        class="case_contain"
        v-for="(item, index) in classicalList"
        :key="index"
        @click="toPage('Smarthealthcare', item.id)"
      >
        <div class="image"><img :src="item.imgUrl" /></div>
        <div class="classical_title">
          <div>{{ item.subjectName }}</div>
          <div class="arrow">
            <img
              class="normal"
              src="@/assets/img/home/箭头_向右-正常状态.png"
            />
            <img class="hover" src="@/assets/img/home/箭头_向右-选中.png" />
          </div>
        </div>
        <div class="content">
          <div class="date">
            <div class="year">{{ item.year }}</div>
            <div class="day">{{ item.day }}</div>
          </div>
          <div class="desc">
            {{ item.briefIntroduction }}
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="news">
    <div class="title">
      {{ newsName }}
      <div class="more" @click="toPage('New')">
        <span class="text-group_2" v-if="language == 'zh'">查看更多</span>
        <span class="text-group_2" v-if="language == 'en'">More</span>
        <img class="normal" src="@/assets/img/home/箭头_向右-正常状态.png" />
      </div>
    </div>
    <div class="news_content">
      <div class="left">
        <template v-for="(item, index) in newsList" :key="index">
          <div
            class="case_contain"
            v-if="index == 0"
            @click="toPage('Commoditypage', item.id)"
          >
            <div class="image"><img :src="item.imgUrl" /></div>
            <div class="classical_title">
              <div>{{ item.title }}</div>
              <div class="arrow">
                <img
                  class="normal"
                  src="@/assets/img/home/箭头_向右-正常状态.png"
                />
                <img class="hover" src="@/assets/img/home/箭头_向右-选中.png" />
              </div>
            </div>
            <div class="content">
              <div class="date">
                <div class="year">{{ item.year }}</div>
                <div class="day">{{ item.day }}</div>
              </div>
              <div class="desc">
                {{ item.briefIntroduction }}
              </div>
            </div>
          </div>
          <div
            class="news_contain"
            v-if="index == 1"
            @click="toPage('Commoditypage', item.id)"
          >
            <div class="date">
              <div class="year">{{ item.year }}</div>
              <div class="day">{{ item.day }}</div>
            </div>
            <div class="desc">
              <div class="news_title">{{ item.title }}</div>
              <div class="text">{{ item.briefIntroduction }}</div>
            </div>
          </div>
        </template>
      </div>
      <div class="right">
        <template v-for="(item, index) in newsList" :key="index">
          <div
            class="news_contain"
            v-if="index > 1 && index < 6"
            @click="toPage('Commoditypage', item.id)"
          >
            <div class="date">
              <div class="year">{{ item.year }}</div>
              <div class="day">{{ item.day }}</div>
            </div>
            <div class="desc">
              <div class="news_title">
                {{ item.title }}
              </div>
              <div class="text">{{ item.briefIntroduction }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <div class="partner">
    <div class="title" v-if="language == 'zh'">合作伙伴</div>
    <div class="title" v-if="language == 'en'">Partner</div>
    <div class="partner_container">
      <img class="logo" src="@/assets/img/home/logo-衡阳第四人民医院.png" />
      <img class="logo" src="@/assets/img/home/logo-衡阳中心医院.png" />
      <img class="logo" src="@/assets/img/home/广东省人民医院.png" />
      <img class="logo" src="@/assets/img/home/三水区人民医院.png" />
      <img class="logo" src="@/assets/img/home/常宁市妇幼保健院.png" />
      <img class="logo" src="@/assets/img/home/南方医院.png" />
      <img class="logo" src="@/assets/img/home/logo-茂名市人民医院.png" />
      <img class="logo" src="@/assets/img/home/衡阳中医院.png" />
      <img class="logo" src="@/assets/img/home/广东医科大学.png" />
      <img class="logo" src="@/assets/img/home/南方医院增城分院.png" />
      <img class="logo" src="@/assets/img/home/汕头大学医院附属第一.png" />
      <img class="logo" src="@/assets/img/home/logo-南华大学.png" />
      <img class="logo" src="@/assets/img/home/深圳市儿童医院.png" />
      <img class="logo" src="@/assets/img/home/中山大学附属第三医院.png" />
      <img class="logo" src="@/assets/img/home/深圳市第二人民医院.png" />
    </div>
  </div>
  <commonBottom
    :HardwareList="HardwareList"
    :softwareList="softwareList"
    :columnList="columnList"
  ></commonBottom>
</template>

<script>
import commomNav from "@/components/commonNav.vue";
import commonBottom from "@/components/commonBottom.vue";
import { carouselList, getColumn } from "@/api/api/office";
export default {
  components: { commomNav, commonBottom },
  setup() {},
  created() {
    this.timer = setInterval(() => {
      if (this.media++ >= 99997) {
        this.media = 0;
      } else {
        this.media++;
      }
    }, 10);
    this.screenWidth = window.innerWidth - 17;
    //960为轮播图片原始高度，1920为原始宽度。
    this.bannerHeight = (960 / 1920) * this.screenWidth;
    // 窗口大小发生轮播图改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth - 17;
      this.bannerHeight = (960 / 1920) * this.screenWidth;
    };
    this.language = sessionStorage.getItem("language");
    if (
      !sessionStorage.getItem("column_zh") &&
      sessionStorage.getItem("language") == "zh"
    ) {
      getColumn(sessionStorage.getItem("language")).then((response) => {
        this.columnList = this.handleTree(response, "id");
        sessionStorage.setItem("column_zh", JSON.stringify(this.columnList));
        this.setColumn();
        console.log(this.softwareList);
      });
    } else if (
      !sessionStorage.getItem("column_en") &&
      sessionStorage.getItem("language") == "en"
    ) {
      getColumn(sessionStorage.getItem("language")).then((response) => {
        this.columnList = this.handleTree(response, "id");
        sessionStorage.setItem("column_en", JSON.stringify(this.columnList));
        this.setColumn();
        console.log(this.softwareList);
      });
    } else {
      if (sessionStorage.getItem("language") == "zh") {
        this.columnList = JSON.parse(sessionStorage.getItem("column_zh"));
      }
      if (sessionStorage.getItem("language") == "en") {
        this.columnList = JSON.parse(sessionStorage.getItem("column_en"));
      }
      console.log(this.columnList);
      this.setColumn();
    }
    if (!sessionStorage.getItem("carouselList")) {
      carouselList({ label: 1 }).then((response) => {
        console.log(response);
        this.carouselList = response.rows;
        sessionStorage.setItem(
          "carouselList",
          JSON.stringify(this.carouselList)
        );
      });
    } else {
      this.carouselList = JSON.parse(sessionStorage.getItem("carouselList"));
    }
  },
  methods: {
    setColumn() {
      this.columnList[0].children.forEach((element) => {
        if (element.id == 3) {
          this.solutionName = element.subjectName;
          this.solutionList = element.children;
        }
        if (element.id == 4) {
          element.children.forEach((element2) => {
            if (element2.id == 9) {
              this.HardwareName = element2.subjectName;
              this.HardwareList = element2.children;
            }
            if (element2.id == 10) {
              this.softwareName = element2.subjectName;
              console.log(element2, this.softwareName);
              this.softwareList = element2.children;
            }
          });
        }
        if (element.id == 5) {
          this.classicalName = element.subjectName;
          this.classicalList = element.children;
          this.classicalList.forEach((element2) => {
            if (element2.issueDate) {
              var date = new Date(element2.issueDate);
              element2.year = date.getFullYear();
              var month = date.getMonth() + 1;
              if (month < 10) month = "0" + month;
              var day = date.getDate();
              if (day < 10) day = "0" + day;
              element2.day = month + "-" + day;
            }
          });
        }
        if (element.id == 6) {
          this.newsName = element.subjectName;
          this.newsList = element.children;
          this.newsList.forEach((element2) => {
            if (element2.issueDate) {
              var date = new Date(element2.issueDate);
              element2.year = date.getFullYear();
              var month = date.getMonth() + 1;
              if (month < 10) month = "0" + month;
              var day = date.getDate();
              if (day < 10) day = "0" + day;
              element2.day = month + "-" + day;
            }
          });
        }
      });
    },
    handleTree(data, id, parentId, children, rootId) {
      id = id || "id";
      parentId = parentId || "parentId";
      children = children || "children";
      rootId = rootId || 0;
      //对源数据深度克隆
      const cloneData = JSON.parse(JSON.stringify(data));
      //循环所有项
      const treeData = cloneData.filter((father) => {
        let branchArr = cloneData.filter((child) => {
          //返回每一项的子级数组
          return father[id] === child[parentId];
        });
        branchArr.length > 0 ? (father.children = branchArr) : "";
        //返回第一层
        return father[parentId] === rootId;
      });
      return treeData != "" ? treeData : data;
    },
    toPage(page, id) {
      this.$router.push({
        path: page,
        query: { id: id },
      });
    },
    toMedical(id) {
      this.$router.push({
        path: "Medical",
        query: { id: id },
      });
    },
  },

  data() {
    return {
      language: null,
      columnList: [{ children: [] }],
      media: 0,
      bannerHeight: 0,
      screenWidth: 0,
      carouselList: [], //轮播图
      solutionName: null,
      solutionList: [], //解决方案
      HardwareName: null,
      HardwareList: [], //硬件产品
      softwareName: null,
      softwareList: [], //软件产品
      classicalName: null,
      classicalList: [], //经典案例
      newsName: null,
      newsList: [], //新闻资讯
    };
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  margin-top: -80px;
}
.gift-photo {
  width: 100%;
  height: 340px;
  background-image: url(../assets/img/home/图片.gif);
  overflow: hidden;
  display: flex;
  justify-content: center;

  .gift_container {
    width: 1200px;
    display: flex;
    justify-content: space-between;

    .left {
      .gift-text {
        width: 96px;
        height: 24px;
        font-family: SourceHanSansCN-Bold;
        font-weight: Bold;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 25px;
        color: #ffffff;
      }

      .line {
        width: 30px;
        height: 1px;
        border: 1px solid #999999;
        margin-bottom: 25px;
      }

      .gift-text2 {
        width: 570px;
        height: 70px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;

        font-size: 18px;
        color: #ffffff;
        line-height: 30px;
        margin-bottom: 51px;
      }

      .button1 {
        width: 200px;
        height: 60px;
        font-family: SourceHanSansCN-Medium;
        font-weight: 500;
        font-size: 28px;
        background: #ef770d;

        a {
          color: #eeeeee;
        }
      }

      .button2 {
        width: 200px;
        height: 60px;
        font-family: SourceHanSansCN-Medium;
        font-weight: 500;
        font-size: 28px;
        background: #2c6bff;
        a {
          color: #eeeeee;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      text-align: center;

      .num {
        font-family: SourceHanSansCN-Bold;
        font-weight: Bold;
        font-size: 46px;
        color: #ffffff;
        display: flex;
        align-items: flex-start;

        .unit {
          font-size: 16px;
          color: #999999;
          opacity: 0.66;
          font-family: SourceHanSansCN-Regular;
        }
      }

      .line {
        width: 1px;
        height: 40px;
        border: 1px solid #cccccc;
        margin: 0 40px;
      }

      .text {
        font-size: 18px;
        color: #999999;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.solution {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-weight: Bold;
    font-size: 36px;
    color: #333333;
    margin: 60px 0 40px;
    text-align: center;
  }

  .tab {
    text-align: center;
    margin-bottom: 20px;

    span {
      display: inline-block;
      font-size: 20px;
      color: #666666;
      padding: 0 20px;
      border-right: 1px solid #666666;
      cursor: pointer;
    }

    span:hover {
      color: #2c6bff;
    }

    span:last-child {
      border-right: none;
    }
  }

  .solution_content {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    .case_contain {
      width: 32%;
      margin: 0;
    }
  }

  .case_contain {
    width: 60%;
    display: flex;
    margin: 0 23%;
    flex-direction: column;
    border-bottom: 1px solid #cccccc;
    cursor: pointer;
    .image {
      overflow: hidden;
    }
    .title {
      font-size: 16px;
      color: #333333;
      display: flex;
      justify-content: space-between;
      margin: 20px 0;

      .arrow {
        padding-left: 5px;
        border-left: 1px solid transparent;
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
        }

        .hover {
          display: none;
        }
      }
    }

    .content {
      font-size: 14px;
      color: #666666;
      padding-bottom: 20px;
    }
  }

  .case_contain:hover {
    border-bottom-color: #2c6bff;

    img {
      transition: 0.5s;
      transform: scale(1.1);
    }
    .arrow {
      border-left-color: #cccccc;

      .normal {
        display: none;
      }

      .hover {
        display: block;
      }
    }
  }
}

.Hardware {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .title {
    width: 1200px;
    font-size: 36px;
    color: #333333;
    text-align: center;
    margin: 60px 0 40px;
    position: relative;

    .more {
      font-size: 16px;
      color: #999999;
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 25px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
    .more:hover {
      color: #2c6bff;
    }
  }

  .Hardware_container {
    width: 1240px;
    display: flex;
    flex-wrap: wrap;

    .Hardware_content {
      width: 32%;
      margin: 0 0.5% 20px;
      background: #ffffff;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 20px 25px;
      cursor: pointer;

      .image {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 275px;
        margin-bottom: 10px;
        overflow: hidden;
        img {
          max-height: 100%;
        }
      }

      .name {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .desc {
        font-size: 14px;
        color: #666666;
        text-align: center;
        line-height: 24px;
        margin-bottom: 20px;
      }

      .btn {
        width: 120px;
        height: 40px;
        background: #dedede;
        text-align: center;
        padding: 13px;

        img {
          width: 16px;
          height: 16px;
          display: inline-block;
        }

        .hover {
          display: none;
        }
      }
    }

    .Hardware_content:hover {
      img {
        transition: 0.5s;
        transform: scale(1.1);
      }
      .btn {
        background: #3c4250;

        .normal {
          display: none;
        }

        .hover {
          display: inline-block;
        }
      }
    }
  }
}

.software {
  width: 100%;
  display: flex;
  justify-content: center;
  background: url(../assets/img/home/软件产品-背景图.png);
  margin-top: 20px;

  .software_container {
    width: 1200px;
    padding-bottom: 20px;

    .title {
      font-weight: Bold;
      font-size: 36px;
      color: #ffffff;
      margin: 60px 0 40px;
      text-align: center;
    }

    .carousel_box {
      width: 360px;
      border-radius: 6px;
      border: 1px solid #46e5ff;
      height: 100%;
      margin: 0 120px;

      .title {
        font-size: 20px;
        padding: 30px 0;
        margin: 0;
      }

      .desc {
        font-size: 16px;
        color: #cccccc;
        line-height: 26px;
        padding: 0 20px;
      }

      .image {
        text-align: center;
        padding: 20px;

        .img {
          width: 100px;
          height: 100px;
          display: inline-block;

          img {
            width: 100px;
            height: 100px;
            display: inline-block;
          }
        }
      }

      .hover_content {
        display: none;

        .desc {
          margin-bottom: 20px;
        }
        .img {
          overflow: hidden;

          img {
            filter: drop-shadow(#46e5ff 100px 0);
            transform: translateX(-100px);
          }
        }
      }
    }

    .carousel_box:hover {
      .title {
        background-image: linear-gradient(180deg, #1998ff 0%, #46e5ff 100%);
      }

      .normal_content {
        display: none;
      }

      .hover_content {
        display: block;
      }
    }

    :deep .el-carousel__mask {
      display: none;
    }

    :deep .el-carousel__item--card.is-active {
      transform: translateX(300px) scale(0.83) !important;
    }
  }
}

.classical_case {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .title {
    width: 1200px;
    font-size: 36px;
    color: #333333;
    text-align: center;
    margin: 60px 0 40px;
    position: relative;

    .more {
      font-size: 16px;
      color: #999999;
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 25px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
    .more:hover {
      color: #2c6bff;
    }
  }

  .classical_content {
    width: 1200px;
    display: flex;
    .image {
      height: 200px;
      overflow: hidden;
    }
    .case_contain {
      width: 48.5%;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin-bottom: 40px;

      .classical_title {
        font-size: 16px;
        color: #333333;
        display: flex;
        justify-content: space-between;
        margin: 20px 0;

        .arrow {
          padding-left: 5px;
          border-left: 1px solid transparent;
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
          }

          .hover {
            display: none;
          }
        }
      }

      .content {
        display: flex;

        .date {
          padding-right: 15px;
          border-right: 1px solid #999999;
          display: flex;
          flex-direction: column;
          align-items: center;

          .year {
            font-size: 18px;
            color: #666666;
          }

          .day {
            font-size: 14px;
            color: #999999;
          }
        }

        .desc {
          font-size: 14px;
          color: #666666;
          padding-left: 15px;
        }
      }
    }

    .case_contain:nth-child(odd) {
      margin-right: 1.5%;
    }

    .case_contain:nth-child(even) {
      margin-left: 1.5%;
    }

    .case_contain:hover {
      .classical_title {
        color: #2c6bff;
      }
      img {
        transition: 0.5s;
        transform: scale(1.1);
      }
      .arrow {
        border-left-color: #cccccc;

        .normal {
          display: none;
        }

        .hover {
          display: block;
        }
      }
    }
  }
}

.news {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .title {
    width: 1200px;
    font-size: 36px;
    color: #333333;
    text-align: center;
    margin: 60px 0 40px;
    position: relative;

    .more {
      font-size: 16px;
      color: #999999;
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 25px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
    .more:hover {
      color: #2c6bff;
    }
  }

  .news_content {
    width: 1200px;
    display: flex;

    .left {
      width: 50%;
      padding-right: 40px;
    }

    .right {
      padding-top: 25px;
      border-top: 1px solid #cccccc;
    }

    .case_contain {
      width: 100%;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin-bottom: 22px;

      .image {
        overflow: hidden;
        height: 200px;
      }

      .classical_title {
        font-size: 16px;
        color: #333333;
        display: flex;
        justify-content: space-between;
        margin: 20px 0;

        .arrow {
          padding-left: 5px;
          border-left: 1px solid transparent;
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
          }

          .hover {
            display: none;
          }
        }
      }

      .content {
        display: flex;

        .date {
          padding-right: 15px;
          border-right: 1px solid #999999;
          display: flex;
          flex-direction: column;
          align-items: center;

          .year {
            font-size: 18px;
            color: #666666;
          }

          .day {
            font-size: 14px;
            color: #999999;
          }
        }

        .desc {
          font-size: 14px;
          color: #666666;
          padding-left: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          height: 40px;
        }
      }
    }

    .case_contain:hover {
      .classical_title {
        color: #2c6bff;
      }
      img {
        transition: 0.5s;
        transform: scale(1.1);
      }
      .arrow {
        border-left-color: #cccccc;

        .normal {
          display: none;
        }

        .hover {
          display: block;
        }
      }
    }

    .news_contain {
      width: 100%;
      display: flex;
      cursor: pointer;
      padding-bottom: 25px;
      border-bottom: 1px solid #cccccc;
      margin-bottom: 25px;

      .date {
        padding-right: 15px;
        border-right: 1px solid #999999;
        display: flex;
        flex-direction: column;
        align-items: center;

        .year {
          font-size: 18px;
          color: #666666;
        }

        .day {
          font-size: 14px;
          color: #999999;
        }
      }

      .desc {
        padding-left: 15px;
        width: 540px;

        .news_title {
          font-size: 18px;
          color: #333333;
          line-height: 20px;
          margin-bottom: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .text {
          font-size: 14px;
          color: #666666;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
    }

    .news_contain:hover {
      .news_title {
        color: #2c6bff;
      }
    }
  }
}

.partner {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .title {
    width: 100%;
    font-weight: Bold;
    font-size: 36px;
    color: #333333;
    margin: 60px 0 40px;
    text-align: center;
  }

  .partner_container {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .logo {
      width: 19%;
      margin-bottom: 40px;
    }
  }
}
</style>
