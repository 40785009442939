import request from '@/api/axios'

// 新增立即资讯或申请入驻通用接口
export function applySettledAdd(data) {
    return request({
        url: '/api/open/coff/applySettled/add',
        method: 'post',
        data: data
    })
}

// 获取栏目数据
export function getColumn(language) {
    return request({
        url: '/api/open/coff/subject/' + language,
        method: 'get'
    })
}

// 查询栏目内容
export function getColumnContent(id, language) {
    return request({
        url: '/api/open/coff/content/' + id + '/' + language,
        method: 'get'
    })
}

// 查询轮播图列表
export function carouselList(query) {
    return request({
        url: '/api/open/coff/carousel/list',
        method: 'get',
        params: query
    })
}