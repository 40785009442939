<template>
  <commomNav :active="999" :columnList="columnList" type="2"></commomNav>
  <div class="settlement-top">
    <div class="settlement-title" v-if="language == 'zh'">立即咨询</div>
    <div class="settlement-title" v-if="language == 'en'">Consult Now</div>
    <div class="content" v-if="language == 'zh'">
      <div class="gongsi">
        <div class="image-wrapper_1 flex-col">
          <img
            class="thumbnail_3"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng637bc81883450b36f215c55cfabe2e93cf8dfabb94829459249c5ec4d316f678"
          />
        </div>
        <input
          class="content-right"
          type="input"
          name="companyName"
          placeholder="请输入贵司全称（必填）"
          v-model="submitForm.companyName"
        />
        <div class="hrs"></div>
      </div>

      <div class="xingming">
        <div class="image-wrapper_1 flex-col">
          <img
            class="thumbnail_3"
            referrerpolicy="no-referrer"
            src="../assets/img/settlement/icon-姓名.png"
          />
        </div>

        <input
          class="content-right"
          type="input"
          name="name"
          placeholder="请输入姓名"
          v-model="submitForm.name"
        />
        <div class="hrs"></div>
      </div>
      <div class="phone">
        <div class="image-wrapper_1 flex-col">
          <img
            class="thumbnail_3"
            referrerpolicy="no-referrer"
            src="../assets/img/settlement/icon-手机号码.png"
          />
        </div>
        <input
          class="content-right"
          type="input"
          name="phoneNumber"
          placeholder="请输入手机号（必填）"
          v-model="submitForm.phoneNumber"
        />
        <div class="hrs"></div>
      </div>

      <div class="e-mail">
        <div class="image-wrapper_1 flex-col">
          <img
            class="thumbnail_3"
            referrerpolicy="no-referrer"
            src="../assets/img/settlement/icon-邮箱.png"
          />
        </div>
        <input
          class="content-right"
          type="input"
          name="email"
          placeholder="请输入邮箱地址"
          v-model="submitForm.email"
        />
        <div class="hrs"></div>
      </div>
      <div class="block_8 flex-row justify-between">
        <span class="text_29">留言</span>
        <textarea
          class="text-wrapper_5"
          placeholder="  请输入任何您想咨询的内容"
          v-model="submitForm.message"
        ></textarea>
      </div>
      <div class="block_9 flex-row justify-between">
        <div class="text-wrapper_6 flex-col" @click="submit">
          <span class="text_31">立即咨询</span>
        </div>
        <div class="text-wrapper_7">
          <span class="text_32">详情可联系：</span>

          <span class="text_34">0755-26990800</span>
        </div>
      </div>
    </div>

    <div class="content" v-if="language == 'en'">
      <div class="gongsi">
        <div class="image-wrapper_1 flex-col">
          <img
            class="thumbnail_3"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng637bc81883450b36f215c55cfabe2e93cf8dfabb94829459249c5ec4d316f678"
          />
        </div>
        <input
          class="content-right"
          type="input"
          name="companyName"
          placeholder="Please enter your companyName"
          v-model="submitForm.companyName"
        />
        <div class="hrs"></div>
      </div>

      <div class="xingming">
        <div class="image-wrapper_1 flex-col">
          <img
            class="thumbnail_3"
            referrerpolicy="no-referrer"
            src="../assets/img/settlement/icon-姓名.png"
          />
        </div>

        <input
          class="content-right"
          type="input"
          name="name"
          placeholder="Please enter your name"
          v-model="submitForm.name"
        />
        <div class="hrs"></div>
      </div>
      <div class="phone">
        <div class="image-wrapper_1 flex-col">
          <img
            class="thumbnail_3"
            referrerpolicy="no-referrer"
            src="../assets/img/settlement/icon-手机号码.png"
          />
        </div>
        <input
          class="content-right"
          type="input"
          name="phoneNumber"
          placeholder="Please enter your mobile number"
          v-model="submitForm.phoneNumber"
        />
        <div class="hrs"></div>
      </div>

      <div class="e-mail">
        <div class="image-wrapper_1 flex-col">
          <img
            class="thumbnail_3"
            referrerpolicy="no-referrer"
            src="../assets/img/settlement/icon-邮箱.png"
          />
        </div>
        <input
          class="content-right"
          type="input"
          name="email"
          placeholder="Please enter your email address"
          v-model="submitForm.email"
        />
        <div class="hrs"></div>
      </div>
      <div class="block_8 flex-row justify-between">
        <span class="text_29" style="margin: -75px">Feedback</span>
        <textarea
          class="text-wrapper_5"
          placeholder="  Please enter anything you want to consult"
          v-model="submitForm.message"
        ></textarea>
      </div>
      <div class="block_9 flex-row justify-between">
        <div class="text-wrapper_6 flex-col" @click="submit">
          <span class="text_31">Consult now</span>
        </div>
        <div class="text-wrapper_7">
          <span class="text_32">For details, please contact : </span>

          <span class="text_34">0755-26990800</span>
        </div>
      </div>
    </div>
  </div>
  <div class="settlement-bottom"></div>
  <commonBottom
    :HardwareList="HardwareList"
    :softwareList="softwareList"
    :columnList="columnList"
  ></commonBottom>
</template>

<script>
import commomNav from "@/components/commonNav.vue";
import commonBottom from "../components/commonBottom.vue";
import { carouselList, getColumn, applySettledAdd } from "@/api/api/office";
export default {
  components: { commomNav, commonBottom },
  setup() {},
  created() {
    this.language = sessionStorage.getItem("language");
    if (
      !sessionStorage.getItem("column_zh") &&
      sessionStorage.getItem("language") == "zh"
    ) {
      getColumn(sessionStorage.getItem("language")).then((response) => {
        this.columnList = this.handleTree(response, "id");
        sessionStorage.setItem("column_zh", JSON.stringify(this.columnList));
        this.setColumn();
        console.log(this.softwareList);
      });
    } else if (
      !sessionStorage.getItem("column_en") &&
      sessionStorage.getItem("language") == "en"
    ) {
      getColumn(sessionStorage.getItem("language")).then((response) => {
        this.columnList = this.handleTree(response, "id");
        sessionStorage.setItem("column_en", JSON.stringify(this.columnList));
        this.setColumn();
        console.log(this.softwareList);
      });
    } else {
      if (sessionStorage.getItem("language") == "zh") {
        this.columnList = JSON.parse(sessionStorage.getItem("column_zh"));
      }
      if (sessionStorage.getItem("language") == "en") {
        this.columnList = JSON.parse(sessionStorage.getItem("column_en"));
      }
      console.log(this.columnList);
      this.setColumn();
    }
  },
  methods: {
    setColumn() {
      this.columnList[0].children.forEach((element) => {
        if (element.id == 3) {
          this.solutionList = element.children;
        }
        if (element.id == 4) {
          element.children.forEach((element2) => {
            if (element2.id == 9) {
              this.HardwareList = element2.children;
            }
            if (element2.id == 10) {
              this.softwareList = element2.children;
            }
          });
        }
        if (element.id == 5) {
          this.classicalList = element.children;
        }
        if (element.id == 6) {
          this.newsList = element.children;
        }
      });
    },
    handleTree(data, id, parentId, children, rootId) {
      id = id || "id";
      parentId = parentId || "parentId";
      children = children || "children";
      rootId = rootId || 0;
      //对源数据深度克隆
      const cloneData = JSON.parse(JSON.stringify(data));
      //循环所有项
      const treeData = cloneData.filter((father) => {
        let branchArr = cloneData.filter((child) => {
          //返回每一项的子级数组
          return father[id] === child[parentId];
        });
        branchArr.length > 0 ? (father.children = branchArr) : "";
        //返回第一层
        return father[parentId] === rootId;
      });
      return treeData != "" ? treeData : data;
    },
    submit() {
      console.log(this.submitForm);
      if (!this.submitForm.companyName) {
        this.$message("请先输入贵公司名称");
        return false;
      }
      var reg =
        /^((13\d)|(14[5,7,9])|(15[0-3,5-9])|(166)|(17[0,1,3,5,7,8])|(18[0-9])|(19[8,9]))\d{8}/;
      if (!reg.test(this.submitForm.phoneNumber)) {
        this.$message("请输入正确的手机号");
        return false;
      }
      applySettledAdd(this.submitForm).then((res) => {
        console.log(res);
        this.$message({
          type: "success",
          message: "提交成功",
        });
      });
    },
  },

  data() {
    return {
      columnList: [{ children: [] }],
      media: 500,
      bannerHeight: 0,
      screenWidth: 0,
      carouselList: [], //轮播图
      solutionList: [], //解决方案
      HardwareList: [], //硬件产品
      softwareList: [], //软件产品
      classicalList: [], //经典案例
      newsList: [], //新闻资讯
      language: null,
      submitForm: {
        companyName: null,
        email: "",
        identification: 0,
        message: "",
        name: "",
        phoneNumber: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.settlement-top {
  width: 100%;
  background-image: url(../assets/img/settlement/banner-2.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  .settlement-title {
    width: 100%;
    text-align: center;
    margin-top: 60px;
    font-family: SourceHanSansCN;
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    line-height: 54px;
  }

  .content {
    width: 750px;
    margin: 60px 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .gongsi {
      width: 315px;
      height: 45px;
    }

    .phone {
      width: 315px;
      height: 45px;
      margin-top: 40px;
    }

    .e-mail {
      width: 315px;
      height: 45px;
      margin-top: 40px;
    }
  }

  .xingming {
    width: 315px;
    height: 45px;
  }

  .block_8 {
    width: 750px;
    margin: 50px 0 0 110px;
  }

  .block_9 {
    width: 750px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text-wrapper_7 {
      width: 239px;
      height: 18px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: SourceHanSansCN-Regular;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }
  }
}

.image-wrapper_1 {
  border-radius: 50%;
  height: 44px;
  border: 1px solid rgba(255, 255, 255, 1);
  width: 44px;
  float: left;
}

.thumbnail_3 {
  width: 20px;
  height: 20px;
  margin: 12px 0 0 12px;
}

.content-left {
  border-radius: 50%;
  height: 44px;
  border: 1px solid rgba(255, 255, 255, 1);
  width: 44px;
}

.img-icon {
  width: 20px;
  height: 20px;
  margin: 12px 0 0 12px;
  float: left;
}

.content-right {
  width: 225px;
  height: 14px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  background: transparent;
  white-space: nowrap;
  line-height: 14px;
  margin: 15px 0 0 20px;
}

.content-right::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.hrs {
  width: 261px;
  height: 1px;
  background-color: #ffffff;
  margin-top: 20px;
  margin-left: 56px;
}

.text_29 {
  width: 28px;
  height: 14px;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  margin: -50px;
}

.text-wrapper_5 {
  border-radius: 6px;
  height: 120px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: -15px;
  width: 696px;
  background: transparent;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  display: block;
}

.text-wrapper_5::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.text_30 {
  width: 168px;
  height: 14px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin: 10px 0 0 10px;
}

.text-wrapper_6 {
  box-shadow: 4px 4px 10px 0px rgba(123, 163, 255, 0.5);
  background-color: rgba(44, 107, 255, 1);
  border-radius: 6px;
  height: 60px;
  width: 280px;
  float: left;
  margin-right: 226px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_31 {
  height: 28px;
  color: rgba(238, 238, 238, 1);
  font-size: 28px;
  font-family: SourceHanSansCN-Medium;

  white-space: nowrap;
  line-height: 28px;
  /* margin: 16px 84px 16px 84px; */
}

.text_32 {
  width: 239px;
  height: 18px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-top: 70px;
}

/* .text_33 {
  width: 239px;
  height: 18px;
  color: rgba(238, 238, 238, 1);
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
   margin-top: 70px;
} */

.text_34 {
  width: 239px;
  height: 18px;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
}

.image-text_6 {
  width: 103px;
  margin: 10px 0 11px 21px;
}

.text-group_7 {
  width: 84px;
  height: 14px;
  color: rgba(44, 107, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.group_9 {
  width: 12px;
  height: 10px;
  margin-top: 2px;
  float: right;
}

.block_11 {
  width: 850px;
  height: 23px;
}

.box_8 {
  border-radius: 50%;
  width: 23px;
  height: 23px;
  border: 1px solid rgba(151, 151, 151, 1);
  float: left;
}

.image_6 {
  width: 389px;
  height: 1px;
  margin-top: 11px;
  float: left;
}

.box_9 {
  border-radius: 50%;
  width: 23px;
  height: 23px;
  border: 1px solid rgba(151, 151, 151, 1);
  margin-left: 1px;
  float: left;
}

.box_10 {
  border-radius: 50%;
  width: 23px;
  height: 23px;
  border: 1px solid rgba(151, 151, 151, 1);
  float: right;
}

.block_13 {
  background-color: rgba(240, 244, 255, 0.8);
  border-radius: 6px;
  z-index: 88;
  position: fixed;
  right: 10px;
  top: 40%;
  width: 70px;
  height: 260px;
  border: 1px solid rgba(44, 107, 255, 1);
  justify-content: flex-center;
  cursor: pointer;
}

.box_12 {
  z-index: 82;
  width: 264px;
  height: 103px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngaa91ada82fdcadec7f7ef7eaeb0d7edfca7398113823684d3c6b47218113dbbc) -4px -2px
    no-repeat;
  background-size: 271px 108px;
  justify-content: flex-center;
  position: absolute;
  right: 80px;
  top: 40px;
  display: none;
  padding: 17px;
}

.image-text_8 {
  width: 56px;
  height: 36px;
  margin: 14px 0 0 7px;
}

.image-text_8:hover .box_7 {
  display: block;
}

.group_10 {
  width: 14px;
  height: 16px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng374037336bd4197a2ca6f396c4110ccfddcc9ae22c4c0aa2935009e619c2864e)
    100% no-repeat;
  background-size: 100% 100%;
  margin-left: 21px;
}

.text-group_8 {
  width: 56px;
  height: 14px;
  color: rgba(44, 107, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.image_7 {
  width: 60px;
  height: 1px;
  margin: 14px 0 0 4px;
}

.image-text_9 {
  width: 56px;
  height: 36px;
  margin: 14px 0 0 7px;
}

.image-text_9:hover .box_12 {
  display: block;
}

.thumbnail_4 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
}

.text-group_9 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.image_8 {
  width: 60px;
  height: 1px;
  margin: 14px 0 0 4px;
}

.image-text_10 {
  width: 56px;
  height: 36px;
  margin: 14px 0 0 7px;
}

.thumbnail_5 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
}

.text-group_10 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
  cursor: pointer;
}

.image_9 {
  width: 60px;
  height: 1px;
  margin: 14px 0 0 4px;
}

.image-text_11 {
  width: 56px;
  height: 36px;
  margin: 15px 0 14px 7px;
}

.thumbnail_6 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
}

.text-group_11 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.image-text_7 {
  width: 226px;
  height: 62px;
}

.box_13 {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-top: 1px;
  float: left;
}

.text-group_7 {
  width: 202px;
  height: 62px;
}

.settlement-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;

  .block_10 {
    width: 936px;
    height: 74px;
    position: relative;
    text-align: center;

    .text_35 {
      width: 144px;
      height: 36px;
      color: rgba(51, 51, 51, 1);
      font-size: 36px;
      font-family: SourceHanSansCN-Bold;
      text-align: left;
      white-space: nowrap;
      line-height: 36px;
    }
  }

  .list_1 {
    width: 1024px;
    display: flex;
    padding-left: 44px;
    margin: 20px 0 50px;
    .text-wrapper_8-0 {
      text-align: center;

      .text_37-0 {
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
        font-family: SourceHanSansCN-Medium;
        white-space: nowrap;
        font-weight: 500;
      }

      span {
        display: block;
        font-size: 14px;
        color: #666666;
      }
    }
    .middle {
      margin: 0 229px 0 268px;
    }
  }
}

.text_36 {
  width: 162px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 10px;
  float: left;
  margin-left: -19px;
}

.text_99 {
  width: 162px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 10px;
  margin-left: 10px;
  float: left;
}

.text_37 {
  width: 202px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 10px;
  float: left;
}

.group_8 {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-top: 1px;
  float: left;
}

.block_6 {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-top: 1px;
  float: left;
}
</style>