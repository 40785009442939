<template>
  <commomNav :active="1" :columnList="columnList" type="2"></commomNav>
  <div class="banner">
    <img :src="banner" />
  </div>
  <div class="Medical">
    <div class="title">
      {{ subjectName }}
    </div>
    <div class="Medical_content">
      <div class="tab_row">
        <div
          class="item"
          v-for="(item, index) in solutionList"
          :key="index"
          :class="id == item.id ? 'active' : ''"
          @click="rowSelect(item)"
        >
          {{ item.subjectName }}
        </div>
      </div>
      <div class="box">
        <div class="tab_column" v-if="tabList">
          <div
            class="item"
            v-for="(item, index) in tabList"
            :key="index"
            :class="tabIndex == index ? 'active' : ''"
            @click="columnSelect(item.id, index)"
          >
            <div class="image">
              <img :src="item.imgUrl" v-if="item.imgUrl" />
            </div>
            <div class="text">{{ item.subjectName }}</div>
          </div>
        </div>
        <div class="box_content ck-content" v-html="content"></div>
      </div>
    </div>
  </div>
  <commonBottom
    :HardwareList="HardwareList"
    :softwareList="softwareList"
    :columnList="columnList"
  ></commonBottom>
</template>

<script>
import commomNav from "@/components/commonNav.vue";
import commonBottom from "@/components/commonBottom.vue";
import { getColumnContent, getColumn } from "@/api/api/office";
export default {
  components: { commomNav, commonBottom },
  setup() {},
  watch: {
    "$route.query.id": {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化
        console.log(newVal);
        this.inital();
      },
      deep: true,
    },
  },
  created() {
    this.inital();
  },
  methods: {
    inital() {
      this.id = this.$route.query.id;
      this.tabList = [];
      if (
        !sessionStorage.getItem("column_zh") &&
        sessionStorage.getItem("language") == "zh"
      ) {
        getColumn(sessionStorage.getItem("language")).then((response) => {
          this.columnList = this.handleTree(response, "id");
          sessionStorage.setItem("column_zh", JSON.stringify(this.columnList));
          this.setColumn();
          console.log(this.softwareList);
        });
      } else if (
        !sessionStorage.getItem("column_en") &&
        sessionStorage.getItem("language") == "en"
      ) {
        getColumn(sessionStorage.getItem("language")).then((response) => {
          this.columnList = this.handleTree(response, "id");
          sessionStorage.setItem("column_en", JSON.stringify(this.columnList));
          this.setColumn();
          console.log(this.softwareList);
        });
      } else {
        if (sessionStorage.getItem("language") == "zh") {
          this.columnList = JSON.parse(sessionStorage.getItem("column_zh"));
        }
        if (sessionStorage.getItem("language") == "en") {
          this.columnList = JSON.parse(sessionStorage.getItem("column_en"));
        }
        console.log(this.columnList);
        this.setColumn();
      }
    },
    setColumn() {
      this.columnList[0].children.forEach((element) => {
        if (element.id == 3) {
          this.solutionList = element.children;
          this.banner = element.imgUrl;

          this.solutionList.forEach((element2) => {
            if (element2.id == this.id) {
              this.subjectName = element2.subjectName;
              if (!element2.children) {
                this.getContent(this.id, sessionStorage.getItem("language"));
              } else {
                this.tabList = element2.children;
                this.getContent(
                  this.tabList[0].id,
                  sessionStorage.getItem("language")
                );
              }
            }
          });
        }
        if (element.id == 4) {
          element.children.forEach((element2) => {
            if (element2.id == 9) {
              this.HardwareList = element2.children;
            }
            if (element2.id == 10) {
              this.softwareList = element2.children;
            }
          });
        }
        if (element.id == 5) {
          this.classicalList = element.children;
        }
        if (element.id == 6) {
          this.newsList = element.children;
        }
      });
    },
    getContent(id, language) {
      getColumnContent(id, language).then((response) => {
        console.log(response);
        this.content = response.content;
      });
    },
    handleTree(data, id, parentId, children, rootId) {
      id = id || "id";
      parentId = parentId || "parentId";
      children = children || "children";
      rootId = rootId || 0;
      //对源数据深度克隆
      const cloneData = JSON.parse(JSON.stringify(data));
      //循环所有项
      const treeData = cloneData.filter((father) => {
        let branchArr = cloneData.filter((child) => {
          //返回每一项的子级数组
          return father[id] === child[parentId];
        });
        branchArr.length > 0 ? (father.children = branchArr) : "";
        //返回第一层
        return father[parentId] === rootId;
      });
      return treeData != "" ? treeData : data;
    },
    rowSelect(val) {
      this.id = val.id;
      this.subjectName = val.subjectName;
      if (!val.children) {
        this.tabList = [];
        this.getContent(val.id, sessionStorage.getItem("language"));
      } else {
        this.tabList = val.children;
        this.tabIndex = 0;
        this.getContent(this.tabList[0].id, sessionStorage.getItem("language"));
      }
    },
    columnSelect(id, index) {
      this.tabIndex = index;
      this.getContent(id, sessionStorage.getItem("language"));
    },
  },
  data() {
    return {
      id: null,
      columnList: [{ children: [] }],
      subjectName: null,
      banner: null,
      solutionList: [], //解决方案
      HardwareList: [], //硬件产品
      softwareList: [], //软件产品
      classicalList: [], //经典案例
      newsList: [], //新闻资讯
      tabList: [],
      tabIndex: 0,
      content: null,
    };
  },
};
</script>
  
<style lang="scss" scoped>
.box_content {
  :deep img {
    width: inherit;
  }
}
.Medical {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  .title {
    font-weight: Bold;
    font-size: 36px;
    color: #333333;
    margin: 60px 0 30px;
    text-align: center;
  }

  .Medical_content {
    width: 1200px;

    .tab_row {
      text-align: center;
      margin-bottom: 30px;

      .item {
        display: inline-block;
        padding: 10px 20px;
        border: 1px solid #cccccc;
        border-radius: 6px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        margin: 0 5px;
        cursor: pointer;
      }

      .item.active {
        background: #2c6bff;
        color: #fff;
      }
    }

    .box {
      display: flex;
      margin-bottom: 60px;
      justify-content: center;
      .tab_column {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 0 6px 0 rgba(40, 63, 118, 0.2);
        border-radius: 6px;
        margin-right: 50px;
        .item {
          width: 120px;
          padding: 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-bottom: 5px;
          }

          .text {
            text-align: center;
            font-family: SourceHanSansCN-Regular;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
          }
        }

        .item:last-child {
          border: 0;
        }

        .active {
          .image {
            overflow: hidden;

            img {
              filter: drop-shadow(#2c6bff 30px 0);
              transform: translateX(-30px);
            }
          }

          .text {
            color: #2c6bff;
          }
        }
      }
    }
  }
}
</style>