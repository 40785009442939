<template>
  <div class="footer">
    <div class="block_13 flex-col" v-if="language == 'zh' && fixedShow">
      <div class="image-text_8 flex-col justify-between fix_item" @click="toPage('settlement')">
        <div class="fix_image">
          <img src="@/assets/img/settlement/icon-企业入驻（正常）.png" />
        </div>
        <span class="text-group_8 fix_text">申请入驻</span>
        <div class="box_7 flex-col">
          <span class="tri"></span>
          <span class="text_99">申请成为特约商户商家</span>
          <div class="image-text_6 flex-row justify-between">
            <span class="text-group_7">解锁更多功能</span>
            <span class="group_9 flex-col">
              <img src="@/assets/img/home/箭头_向右-选中.png" class="group_9" alt="" srcset="" /></span>
          </div>
        </div>
      </div>
      <div class="border"></div>
      <div class="image-text_9 flex-col justify-between fix_item">
        <div class="fix_image">
          <img src="@/assets/img/settlement/icon-联系我们（正常）.png" />
        </div>
        <span class="text-group_9 fix_text">联系我们</span>
        <div class="box_12 flex-row">
          <span class="tri"></span>
          <div class="image-text_7 flex-row justify-between">
            <div class="text-group_7 flex-col justify-between">
              <span class="text_35"><img src="@/assets/img/commodity/icon-电话.png" alt="" srcset="" class="box_13" />
                0755-26990800</span>
              <span class="text_36"><img src="@/assets/img/commodity/icon-邮箱.png" alt="" srcset=""
                  class="block_6" />managers&#64;liebaoyi.com</span>
              <span class="text_37"><img src="@/assets/img/commodity/icon-地址.png" class="group_8" alt="" srcset="" />
                深圳高新技术产业园区R2B座6楼</span>
            </div>
          </div>
        </div>
      </div>
      <div class="border"></div>
      <div class="image-text_10 flex-col justify-between fix_item" @click="toPage('consultnow')">
        <div class="fix_image">
          <img src="@/assets/img/settlement/icon-立即咨询（正常）.png" />
        </div>
        <span class="text-group_10 fix_text">立即咨询</span>
      </div>
      <div class="border"></div>
      <div class="image-text_11 flex-col justify-between fix_item" @click="scroll">
        <div class="fix_image">
          <img src="@/assets/img/settlement/icon-返回顶部（正常）.png" />
        </div>
        <span class="text-group_11 fix_text">返回顶部</span>
      </div>
    </div>
    <div class="block_13 flex-col" v-if="language == 'en' && fixedShow">
      <div class="image-text_8 flex-col justify-between fix_item" @click="toPage('settlement')">
        <div class="fix_image">
          <img src="@/assets/img/settlement/icon-企业入驻（正常）.png" />
        </div>
        <span class="text-group_8 fix_text">Apply</span>
        <div class="box_7 flex-col">
          <span class="tri"></span>
          <span class="text_99">Apply to merchant</span>
          <div class="image-text_6 flex-row justify-between">
            <span class="text-group_7">Unlock more</span>
            <span class="group_9 flex-col">
              <img src="@/assets/img/home/箭头_向右-选中.png" class="group_9" alt="" srcset="" /></span>
          </div>
        </div>
      </div>
      <div class="border"></div>
      <div class="image-text_9 flex-col justify-between fix_item">
        <div class="fix_image">
          <img src="@/assets/img/settlement/icon-联系我们（正常）.png" />
        </div>
        <span class="text-group_9 fix_text">Contact</span>
        <div class="box_12 flex-row">
          <span class="tri"></span>
          <div class="image-text_7 flex-row justify-between">
            <div class="text-group_7 flex-col justify-between">
              <span class="text_35"><img src="@/assets/img/commodity/icon-电话.png" alt="" srcset="" class="box_13" />
                0755-26990800</span>
              <span class="text_36"><img src="@/assets/img/commodity/icon-邮箱.png" alt="" srcset=""
                  class="block_6" />managers&#64;liebaoyi.com</span>
              <span class="text_37"><img src="@/assets/img/commodity/icon-地址.png" class="group_8" alt="" srcset="" />
                Shenzhen High Tech Industrial</span>
            </div>
          </div>
        </div>
      </div>
      <div class="border"></div>
      <div class="image-text_10 flex-col justify-between fix_item" @click="toPage('consultnow')">
        <div class="fix_image">
          <img src="@/assets/img/settlement/icon-立即咨询（正常）.png" />
        </div>
        <span class="text-group_10 fix_text">Consult</span>
      </div>
      <div class="border"></div>
      <div class="image-text_11 flex-col justify-between fix_item" @click="scroll">
        <div class="fix_image">
          <img src="@/assets/img/settlement/icon-返回顶部（正常）.png" />
        </div>
        <span class="text-group_11 fix_text">Back</span>
      </div>
    </div>
    <div class="footer_container">
      <template v-for="(item, index) in columnList" :key="index">
        <template v-if="item.id == 1" v-for="(item2, index2) in item.children" :key="index">
          <template v-if="item2.id == 4" v-for="(item3, index3) in item2.children" :key="index">
            <div class="box" v-if="item3.id == 9">
              <div class="name">{{ item3.subjectName }}</div>
              <div class="box_content">
                <template v-for="(item, index) in item3.children" :key="index">
                  <div class="item" v-if="index < 5" @click="toPage('Hardware', item.id)" style="cursor: pointer">
                    {{ item.subjectName }}
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>
      </template>
      <template v-for="(item, index) in columnList" :key="index">
        <template v-if="item.id == 1" v-for="(item2, index2) in item.children" :key="index">
          <template v-if="item2.id == 4" v-for="(item3, index3) in item2.children" :key="index">
            <div class="box" v-if="item3.id == 10">
              <div class="name">{{ item3.subjectName }}</div>
              <div class="box_content">
                <template v-for="(item, index) in item3.children" :key="index">
                  <div class="item" v-if="index < 5" @click="toPage('Hardware', item.id)" style="cursor: pointer">
                    {{ item.subjectName }}
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>
      </template>

      <template v-for="(item, index) in columnList" :key="index">
        <template v-if="item.id == 1" v-for="(item2, index2) in item.children" :key="index">
          <div class="box" v-if="item2.id == 7">
            <div class="name" @click="toPage('Aboutus')" style="cursor: pointer">
              {{ item2.subjectName }}
            </div>
            <div class="box_content" v-if="item2.children">
              <template v-for="(item3, index3) in item2.children" :key="index3">
                <div class="item" v-if="index3 < 5" @click="toPage('Aboutus', item3.id)" style="cursor: pointer">
                  {{ item3.subjectName }}
                </div>
              </template>
            </div>
          </div>
        </template>
      </template>
      <div class="box" v-if="language == 'zh'">
        <div class="name">联系我们</div>
        <div class="box_content">
          <div class="item">
            <img src="@/assets/img/settlement/icon-公司.png" />
            深圳市创鑫互联通信技术有限公司
          </div>
          <div class="item">
            <img src="@/assets/img/settlement/icon-地址.png" />
            深圳高新技术产业园区R2B座6楼
          </div>
          <div class="item">
            <img src="@/assets/img/settlement/icon-电话.png" />
            0755-26990800
          </div>
          <div class="item">
            <img src="@/assets/img/settlement/icon-邮箱.png" />
            managers@liebaoyi.com
          </div>
        </div>
      </div>
      <div class="box" v-if="language == 'en'">
        <div class="name">Contact us</div>
        <div class="box_content">
          <div class="item" style="align-items: flex-start;">
            <img src="@/assets/img/settlement/icon-公司.png" />
            ShenZhen CXHL Communications Technology <br>Co. ,Ltd, Shenzhen, Guangdong, China, 518063
          </div>
          <div class="item">
            <img src="@/assets/img/settlement/icon-地址.png" />
            6th floor, R2B building, Shenzhen High Tech Industrial Park
          </div>
          <div class="item">
            <img src="@/assets/img/settlement/icon-电话.png" />
            0755-26990800
          </div>
          <div class="item">
            <img src="@/assets/img/settlement/icon-邮箱.png" />
            managers@liebaoyi.com
          </div>
        </div>
      </div>
      <div class="box">
        <img class="code" src="@/assets/img/home/code.png" />
        <div class="code_text" v-if="language == 'zh'">创鑫互联官方微信</div>
        <div class="code_text" v-if="language == 'en'">
          CXHL Official Wechat
        </div>
      </div>
    </div>
    <div class="Copyright" v-if="language == 'zh'">
      <a href="https://beian.miit.gov.cn">Copyright©创鑫互联通讯有限公司 | 备案号：粤ICP备17114846号</a>
    </div>
    <div class="Copyright" v-if="language == 'en'">
      <a href="https://beian.miit.gov.cn">Copyright©CXHL Communications Technology Company | Record :
        Guangdong ICP
        No. 17114846</a>
    </div>
  </div>
</template>
<script>
export default {
  props: ["softwareList", "HardwareList", "columnList"],
  setup() { },
  created() {
    this.language = sessionStorage.getItem("language");
    var _this = this;
    window.onscroll = function () {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 1000) {
        _this.fixedShow = true;
      } else {
        _this.fixedShow = false;
      }
    };
  },
  methods: {
    scroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop, "scrollTop");
      if (scrollTop > 0) {
        const timeTop = setInterval(() => {
          document.documentElement.scrollTop =
            document.body.scrollTop =
            scrollTop -=
            50; //一次减50往上滑动
          if (scrollTop <= 0) {
            clearInterval(timeTop);
          }
        }, 10);
      }
    },
    toPage(page, id) {
      this.$router.push({
        path: page,
        query: { id: id },
      });
    },
  },
  data() {
    return {
      language: null,
      fixedShow: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background: #0f1927;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer_container {
    width: 1200px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 120px;

    .name {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 40px;
    }

    .box {
      font-size: 14px;
      color: #eeeeee;

      .item {
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        img {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }

      .code_text {
        text-align: center;
        margin-top: 10px;
      }
    }
  }

  .Copyright {
    text-align: center;
    font-size: 14px;

    a {
      color: #ffffff;
      cursor: pointer;
    }
  }
}

.block_13 {
  background-color: rgba(240, 244, 255, 0.8);
  border-radius: 6px;
  z-index: 88;
  position: fixed;
  right: 10px;
  top: 40%;
  width: 70px;
  height: 260px;
  border: 1px solid rgba(44, 107, 255, 1);
  justify-content: flex-center;
  cursor: pointer;
  text-align: center;
}

.box_12 {
  z-index: 82;
  width: 264px;
  height: 103px;
  background: #ffffff;
  border-radius: 6px;
  background-size: 271px 108px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  justify-content: flex-center;
  position: absolute;
  right: 80px;
  top: 40px;
  display: none;
  padding: 17px;

  .tri {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: -12px;
    margin: -6px 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid transparent;
    border-left: 6px solid #ffffff;
  }
}

.fix_image {
  text-align: center;

  img {
    width: 16px;
    height: 16px;
    display: inline-block;
  }
}

.fix_item:hover {
  .fix_text {
    color: rgb(44, 107, 255);
  }

  .fix_image {
    overflow: hidden;

    img {
      filter: drop-shadow(rgb(44, 107, 255) 100px 0);
      transform: translateX(-100px);
    }
  }
}

.image-text_8 {
  width: 56px;
  height: 36px;
  margin: 14px 0 0 7px;
}

.image-text_8:hover .box_7 {
  display: block;
}

.group_10 {
  width: 14px;
  height: 16px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng374037336bd4197a2ca6f396c4110ccfddcc9ae22c4c0aa2935009e619c2864e) 100% no-repeat;
  background-size: 100% 100%;
  margin-left: 21px;
}

.text-group_8 {
  width: 56px;
  height: 14px;
  color: rgb(51, 51, 51);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.image_7 {
  width: 60px;
  height: 1px;
  margin: 14px 0 0 4px;
}

.image-text_9 {
  width: 56px;
  height: 36px;
  margin: 14px 0 0 7px;
}

.image-text_9:hover .box_12 {
  display: block;
}

.thumbnail_4 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
}

.text-group_9 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.image_8 {
  width: 60px;
  height: 1px;
  margin: 14px 0 0 4px;
}

.image-text_10 {
  width: 56px;
  height: 36px;
  margin: 14px 0 0 7px;
}

.thumbnail_5 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
}

.text-group_10 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
  cursor: pointer;
}

.image_9 {
  width: 60px;
  height: 1px;
  margin: 14px 0 0 4px;
}

.image-text_11 {
  width: 56px;
  height: 36px;
  margin: 15px 0 14px 7px;
}

.thumbnail_6 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
}

.text-group_11 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.image-text_7 {
  width: 226px;
  height: 62px;
}

.box_13 {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-top: 1px;
  float: left;
}

.text-group_7 {
  width: 202px;
  height: 62px;
}

.box_7 {
  width: 164px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-size: 171px 68px;
  margin-top: 14px;
  justify-content: flex-center;
  position: fixed;
  top: 39%;
  right: 85px;
  display: flex;
  flex-direction: column;
  display: none;

  .tri {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: -12px;
    margin: -6px 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid transparent;
    border-left: 6px solid #ffffff;
  }
}

.group_9 {
  width: 12px;
  height: 10px;
  margin-top: 2px;
  float: right;
}

.image-text_6 {
  width: 103px;
  margin: 10px 0 11px 21px;
}

.text-group_7 {
  width: 84px;
  height: 14px;
  color: rgba(44, 107, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.group_8 {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-top: 1px;
  float: left;
}

.block_6 {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-top: 1px;
  float: left;
}

.text_36 {
  width: 162px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 10px;
  float: left;
}

.text_99 {
  width: 162px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 10px;
  margin-left: 10px;
  float: left;
}

.text_37 {
  width: 202px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 10px;
  float: left;
}

.block_10 {
  width: 936px;
  height: 74px;
  position: relative;
  text-align: center;

  .text_35 {
    width: 144px;
    height: 36px;
    color: rgba(51, 51, 51, 1);
    font-size: 36px;
    font-family: SourceHanSansCN-Bold;
    text-align: left;
    white-space: nowrap;
    line-height: 36px;
  }
}

.border {
  background: #2c6bff;
  height: 1px;
  margin: 15px 4px 0;
}
</style>
