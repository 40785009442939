<template>
    <div class="home_container" :class="type == 2 ? 'white' : ''">
        <div class="home-nav" @mouseleave="orgActive = active;">
            <span class="logo">
                <img v-show="type != '2'" class='logo' src="../../public/logo.png" alt="" />
                <img v-show="type == '2'" class='logo' src="@/assets/img/home/logo_2.png" alt="" />
            </span>
            <div class="nav_box">
                <template v-for="(item, index) in columnList[0].children" :key="index">
                    <router-link to="/" v-if="item.id == 2" @mouseover="getDropDown(item, index)">
                        <span class="column_1" :class="orgActive == 0 ? 'active' : ''">{{ item.subjectName }}<span
                                class="tri"></span></span>
                    </router-link>
                </template>
                <template v-for="(item, index) in columnList[0].children" :key="index">
                    <div class="nav_item" v-if="item.id == 3" @mouseover="getDropDown(item, index)">
                        <span class="column_1" :class="orgActive == 1 ? 'active' : ''">{{ item.subjectName }}<span
                                class="tri"></span></span>
                        <div class="dropDown2">
                            <div class="dropDown_item" v-for="(item2, index2) in item.children" :key="index2" @click="toPage('medical',item2.id)">
                                {{ item2.subjectName }}</div>
                        </div>
                    </div>
                </template>
                <template v-for="(item, index) in columnList[0].children" :key="index">
                    <div v-if="item.id == 4" @mouseover="getDropDown(item, index)">
                        <span class="column_1" :class="orgActive == 2 ? 'active' : ''">{{ item.subjectName }}<span
                                class="tri"></span></span>
                    </div>
                </template>
                <!-- <template v-for="(item, index) in columnList[0].children" :key="index">
                    <router-link to="/case" v-if="item.id == 5" @mouseover="getDropDown(item, index)">
                        <span class="column_1" :class="orgActive == 3 ? 'active' : ''">{{ item.subjectName }}<span
                                class="tri"></span></span>
                    </router-link>
                </template> -->
                <template v-for="(item, index) in columnList[0].children" :key="index">
                    <router-link to="/new" v-if="item.id == 6" @mouseover="getDropDown(item, index)">
                        <span class="column_1" :class="orgActive == 4 ? 'active' : ''">{{ item.subjectName }}<span
                                class="tri"></span></span>
                    </router-link>
                </template>
                <template v-for="(item, index) in columnList[0].children" :key="index">
                    <router-link to="/aboutus" v-if="item.id == 7" @mouseover="getDropDown(item, index)">
                        <span class="column_1" :class="orgActive == 5 ? 'active' : ''">{{ item.subjectName }}<span
                                class="tri"></span></span>
                    </router-link>
                </template>
                <div class="dropDown" v-show="dropDownShow" @mouseleave="orgActive = active; dropDownShow = false">
                    <div class="dropDown_container">
                        <div class="left">
                            <div v-for="(item, index) in dropDownTabList" :class="tabActive == index ? 'active' : ''"
                                :key="index" @mouseover="tabSelect(item, index)">{{ item.subjectName }}</div>
                        </div>
                        <div class="right">
                            <div><span v-for="(item, index) in dropDownItemList" :key="index"
                                @click="toPage('Hardware', item.id)" >{{ item.subjectName }}</span></div>
                            
                        </div>
                    </div>
                </div>

            </div>

            <div class="language">
                <img class='language_icon' v-show="type != '2'" src="@/assets/img/settlement/icon-多语言.png" alt="" />
                <img class='language_icon' v-show="type == '2'" src="@/assets/img/settlement/icon-多语言_2.png" alt="" />
                <span class="ZH" :class="language == 'zh' ? 'active' : ''" @click="setlanguage('zh')">简</span> <span
                    class="text_8">/</span>
                <span class="EN" :class="language == 'en' ? 'active' : ''" @click="setlanguage('en')">EN</span>
            </div>
            <!-- <span class="text_10">登录</span> -->
        </div>
    </div>
</template>

<script>
export default {
    props: ['active', 'columnList', 'type'],
    setup() { },
    created() {
        console.log(this.active)
        this.orgActive = this.active;
        this.language = sessionStorage.getItem('language');
    },
    watch: {
        columnList(val) {
            console.log(val)
        }
    },
    methods: {
        setlanguage(val) {
            sessionStorage.setItem('language', val);
            window.location.reload();
        },
        getDropDown(val, index) {
            this.dropDownItemList = [];
            this.tabActive = 0;
            if (val.id == 4) {
                this.dropDownShow = true;
                this.dropDownTabList = val.children;
                if (this.dropDownTabList[0].children) {
                    this.dropDownItemList = this.dropDownTabList[0].children;
                }
            } else {
                this.dropDownShow = false;
            }
            console.log(index);
            this.orgActive = index;
           
        },
        tabSelect(val, index) {
            
            this.dropDownItemList = val.children;
            console.log(this.dropDownItemList)
            this.tabActive = index;
        },
        toPage(page, id) {
            this.$router.push({
                path: page,
                query: { id: id }
            })
        },
    },
    data() {
        return {
            orgActive: null,
            dropDownShow: false,
            dropDownTabList: [],
            dropDownItemList: [],
            tabActive: 0,
            language: null
        };
    },
};
</script>

<style lang="scss" scoped>
.home_container {
    width: 100%;
    height: 80px;
    line-height: 80px;
    background-color: rgba(25,41,62,0.84);;
    color: azure;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 999;
    top:0;
    left:0;
    .home-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 80px;
        font-family: SourceHanSansCN-Regular;
        font-size: 18px;
        width: 90%;

        .logo {
            margin-right: 50px;

            img {
                width: 205px;
            }
        }

        .column_1 {
            padding: 0 30px;
            display: inline-block;
            line-height: 80px;
            white-space: nowrap;
            color: #FFFFFF;
            position: relative;

            .tri {
                position: absolute;
                right: 50%;
                bottom: 0;
                margin: 0 -10px;
                border: 10px solid #000;
                border-top-color: transparent;
                border-bottom-color: #2C6BFF;
                border-left-color: transparent;
                border-right-color: transparent;
                display: none;
            }
        }

        .column_1:hover {
            color: #2C6BFF;
        }

        .active {
            color: #2C6BFF !important;

            .tri {
                display: block !important;
            }
        }

        .dropDown {
            position: absolute;
            z-index: 9999;
            top: 80px;
            width: 100%;
            left: 0;
            background: rgba(255, 255, 255, 0.80);
            color: #333333;
            display: flex;
            justify-content: center;
            line-height: initial;

            .dropDown_container {
                width: 1200px;
                display: flex;
                padding: 20px 0;

                .left {
                    border-right: 1px solid #999999;
                    font-size: 14px;

                    div {
                        padding: 5px 30px;
                        color: #999999;
                        border-left: 4px solid transparent;
                        margin: 20px 0;
                        cursor: pointer;
                    }

                    .active {
                        border-left: 4px solid #2C6BFF;
                        color: #333333;
                        font-weight: bolder;
                    }
                }

                .right {
                    padding-left: 50px;
                    width:580px;
                    span {
                        display: inline-block;
                        margin: 10px;
                        font-size: 14px;
                        cursor: pointer;
                    }

                    span:hover {
                        color: #2C6BFF;
                    }
                }
            }
        }

        .nav_box {
            width: 800px;
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

        .language {
            margin-left: 30px;
            display: flex;
            align-items: center;

            .language_icon {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }

            span {
                cursor: pointer;
            }
        }

        .nav_item {
            position: relative;

            .dropDown2 {
                position: absolute;
                top: 80px;
                left: 0;
                width: 100%;
                background: rgba(255, 255, 255, 0.80);
                border-radius: 0 0 6px 6px;
                border-radius: 0 0 6px 6px;
                padding: 5px 0;
                z-index: 999;
                .dropDown_item {
                    font-size: 14px;
                    color: #333333;
                    line-height: normal;
                    text-align: center;
                    padding: 5px;
                    cursor: pointer;
                }
                .dropDown_item:hover{
                    color: #2C6BFF;
                }
                display: none;
            }
        }
        .nav_item:hover{
            .dropDown2{
                display: block;
            }
        }
    }
}

.white {
    background: #fff;
    color: #666666;

    .home-nav {
        .column_1 {
            color: #666666;
        }
    }
}
</style>