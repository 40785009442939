<template>
    <div id="app" ref="main">
        <router-view />
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    name: "App",
    computed: {
        ...mapState(["network"]),
    },
    watch: {
        $route() {
            window.scrollTo(0, 0);
        }
    },
    created() {
        if (!sessionStorage.getItem('language')) {
            sessionStorage.setItem('language','zh')
        };
    },
    methods: {
        // 通过跳转一个空页面再返回的方式来实现刷新当前页面数据的目的
        onRefresh() {
            this.$router.replace("/refresh");
        },
    }
};
</script>

