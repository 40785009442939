<template>
  <commomNav :active="5" :columnList="columnList" type="2"></commomNav>
  <div class="aboutus">
    <div class="aboutus-photo">
      <img :src="banner" />
    </div>
    <div class="text-group_1 flex-col justify-between">
      <span class="text_11">{{ subjectName }}</span>
    </div>
    <div class="box_3 flex-row justify-between">
      <div
        class="text-wrapper_3 flex-col"
        v-for="(item, index) in aboutUsList"
        :key="index"
        @click="tabSelect(index)"
        :class="tabIndex == index ? 'active' : ''"
      >
        <span class="text_14">{{ item.subjectName }}</span>
      </div>
    </div>

    <div class="box_4 flex-row justify-between">
      <div class="box_5 flex-col">
        <div
          class="group_2 flex-row justify-between htmlContent ck-content"
          v-html="content"
        ></div>
        <div class="box" v-show="tabIndex == 0">
          <span class="text_16" v-if="language == 'zh'">企业价值观</span>
          <span class="text_16" v-if="language == 'en'">Corporate Values</span>
        </div>

        <div class="list_1 flex-row" v-show="tabIndex == 0">
          <div class="list-items_1-0 flex-col box_content">
            <div class="text-wrapper_5-0 flex-col">
              <span class="text_18-0" v-if="language == 'zh'">正直</span>
              <span class="text_18-0" v-if="language == 'en'">Integrity</span>
            </div>
          </div>
          <div class="list-items_1-1 flex-col box_content">
            <div class="text-wrapper_5-1 flex-col">
              <span class="text_18-1" v-if="language == 'zh'">进取</span>
              <span class="text_18-1" v-if="language == 'en'"
                >Enterprising</span
              >
            </div>
          </div>
          <div class="list-items_1-2 flex-col box_content">
            <div class="text-wrapper_5-2 flex-col">
              <span class="text_18-2" v-if="language == 'zh'">协作</span>
              <span class="text_18-2" v-if="language == 'en'">Cooperation</span>
            </div>
          </div>
          <div class="list-items_1-3 flex-col box_content">
            <div class="text-wrapper_5-3 flex-col">
              <span class="text_18-3" v-if="language == 'zh'">创造</span>
              <span class="text_18-3" v-if="language == 'en'">Creation</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_7 flex-col" v-show="tabIndex == 0">
      <div class="section_3 flex-col">
        <div class="text_19" v-if="language == 'zh'">愿景</div>
        <div class="text_19" v-if="language == 'en'">Vision</div>
        <div class="text_21" v-if="language == 'zh'">
          为用户提供高质量的软硬件服务
        </div>
        <div class="text_21" v-if="language == 'en'">
          Provide users with high-quality software and hardware services
        </div>
      </div>
    </div>
  </div>

  <commonBottom
    :HardwareList="HardwareList"
    :softwareList="softwareList"
    :columnList="columnList"
  ></commonBottom>
</template>
  
<script>
import commomNav from "@/components/commonNav.vue";
import commonBottom from "@/components/commonBottom.vue";
import { getColumnContent, getColumn } from "@/api/api/office";
export default {
  components: { commomNav, commonBottom },
  setup() {},
  watch: {
    "$route.query.id": {
      handler(newVal, oldVal) {
        //判断newVal有没有值监听路由变化
        console.log(newVal);
        this.inital();
      },
      deep: true,
    },
  },
  created() {
    this.inital();
  },
  methods: {
    inital() {
      this.language = sessionStorage.getItem("language");
      if (
        !sessionStorage.getItem("column_zh") &&
        sessionStorage.getItem("language") == "zh"
      ) {
        getColumn(sessionStorage.getItem("language")).then((response) => {
          this.columnList = this.handleTree(response, "id");
          sessionStorage.setItem("column_zh", JSON.stringify(this.columnList));
          this.setColumn();
          console.log(this.softwareList);
        });
      } else if (
        !sessionStorage.getItem("column_en") &&
        sessionStorage.getItem("language") == "en"
      ) {
        getColumn(sessionStorage.getItem("language")).then((response) => {
          this.columnList = this.handleTree(response, "id");
          sessionStorage.setItem("column_en", JSON.stringify(this.columnList));
          this.setColumn();
          console.log(this.softwareList);
        });
      } else {
        if (sessionStorage.getItem("language") == "zh") {
          this.columnList = JSON.parse(sessionStorage.getItem("column_zh"));
        }
        if (sessionStorage.getItem("language") == "en") {
          this.columnList = JSON.parse(sessionStorage.getItem("column_en"));
        }
        console.log(this.columnList);
        this.setColumn();
      }
    },
    setColumn() {
      this.columnList[0].children.forEach((element) => {
        if (element.id == 3) {
          this.solutionList = element.children;
        }
        if (element.id == 4) {
          element.children.forEach((element2) => {
            if (element2.id == 9) {
              this.HardwareList = element2.children;
            }
            if (element2.id == 10) {
              this.softwareList = element2.children;
            }
          });
        }
        if (element.id == 5) {
          this.classicalList = element.children;
        }
        if (element.id == 6) {
          this.newsList = element.children;
        }
        if (element.id == 7) {
          this.banner = element.imgUrl;
          this.aboutUsList = element.children;
          if (this.$route.query.id) {
            this.aboutUsList.forEach((element, index) => {
              if (this.$route.query.id == element.id) {
                this.tabIndex = index;
              }
            });
          }
          this.subjectName = this.aboutUsList[this.tabIndex].subjectName;
          this.getContent(
            this.aboutUsList[this.tabIndex].id,
            sessionStorage.getItem("language")
          );
        }
      });
    },
    handleTree(data, id, parentId, children, rootId) {
      id = id || "id";
      parentId = parentId || "parentId";
      children = children || "children";
      rootId = rootId || 0;
      //对源数据深度克隆
      const cloneData = JSON.parse(JSON.stringify(data));
      //循环所有项
      const treeData = cloneData.filter((father) => {
        let branchArr = cloneData.filter((child) => {
          //返回每一项的子级数组
          return father[id] === child[parentId];
        });
        branchArr.length > 0 ? (father.children = branchArr) : "";
        //返回第一层
        return father[parentId] === rootId;
      });
      return treeData != "" ? treeData : data;
    },
    getContent(id, language) {
      getColumnContent(id, language).then((response) => {
        console.log(response);
        this.content = response.content;
      });
    },
    tabSelect(index) {
      this.tabIndex = index;
      this.subjectName = this.aboutUsList[this.tabIndex].subjectName;
      this.getContent(
        this.aboutUsList[index].id,
        sessionStorage.getItem("language")
      );
    },
  },

  data() {
    return {
      columnList: [{ children: [] }],
      solutionList: [], //解决方案
      HardwareList: [], //硬件产品
      softwareList: [], //软件产品
      classicalList: [], //经典案例
      newsList: [], //新闻资讯
      aboutUsList: [],
      subjectName: null,
      content: null,
      tabIndex: 0,
      language: null,
    };
  },
};
</script>
  
  <style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.htmlContent {
  :deep img {
    width: inherit;
  }
}
.content {
  height: 500px;
  width: 100%;
}

.aboutus {
  display: flex;
  flex-direction: column;
  align-items: center;

  .aboutus-photo {
    width: 100%;
  }

  .text-group_1 {
    text-align: center;
    margin: 60px 0 30px;
  }

  .box_3 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.box_content {
  span {
    display: inline-block;
  }
}
.text_11 {
  width: 144px;
  height: 36px;
  color: rgba(51, 51, 51, 1);
  font-size: 36px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 36px;
}

.text_12 {
  width: 109px;
  height: 14px;
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin: 10px 0 0 17px;
}

.text-wrapper_3 {
  border-radius: 6px;
  height: 36px;
  border: 1px solid rgba(204, 204, 204, 1);
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
  padding: 0 10px;
}

.text_14 {
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}
.active {
  background-color: rgba(44, 107, 255, 1);
  .text_14 {
    color: rgba(255, 255, 255, 1);
  }
}
.box_4 {
  width: 1200px;
}

.box_5 {
  width: 1200px;
}

.group_2 {
  width: 1200px;
}

.section_2 {
  border-radius: 6px;
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/87545adea0294c9e9e472e36c02a0bff_mergeImage.png);
  width: 440px;
  height: 240px;
  float: left;
}

.text-wrapper_4 {
  width: 700px;
  height: 208px;
  margin-top: 16px;
  float: left;
  margin-left: 60px;
}

.text_15 {
  width: 270px;
  height: 18px;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-left: 8px;
  font-weight: bold;
  margin-bottom: 20px;
}

.paragraph_1 {
  width: 700px;
  height: 170px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  line-height: 28px;
  margin-top: 20px;
}

.text_16 {
  width: 100px;
  height: 20px;
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 60px 0 0 550px;
  font-weight: bold;
}

.text_17 {
  width: 112px;
  height: 14px;
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin: 10px 0 0 544px;
}

.list_1 {
  width: 1240px;
  height: 260px;
  margin-top: 30px;
  justify-content: space-between;
}

.list-items_1-0 {
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/4e961e32d4464acda15f8d9fbbd535d5_mergeImage.png);
  height: 260px;
  margin-right: 40px;
  width: 270px;
  background: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/4e961e32d4464acda15f8d9fbbd535d5_mergeImage.png);
  float: left;
}

.text-wrapper_5-0 {
  background-color: rgba(0, 0, 0, 0.3);
  height: 260px;
  width: 270px;
}

.text_18-0 {
  width: 48px;
  height: 24px;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin: 20px 0 0 20px;
}

.list-items_1-1 {
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/4e961e32d4464acda15f8d9fbbd535d5_mergeImage.png);
  height: 260px;
  margin-right: 40px;
  width: 270px;
  background: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/4d5fabd7efa641749a03a05b35cbb9c5_mergeImage.png);
  float: left;
}

.text-wrapper_5-1 {
  background-color: rgba(0, 0, 0, 0.3);
  height: 260px;
  width: 270px;
}

.text_18-1 {
  width: 48px;
  height: 24px;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin: 20px 0 0 20px;
}

.list-items_1-2 {
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/4e961e32d4464acda15f8d9fbbd535d5_mergeImage.png);
  height: 260px;
  margin-right: 40px;
  width: 270px;
  background: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/be71407fbd3c437398d40d741da9e7f5_mergeImage.png);
  float: left;
}

.text-wrapper_5-2 {
  background-color: rgba(0, 0, 0, 0.3);
  height: 260px;
  width: 270px;
}

.text_18-2 {
  width: 48px;
  height: 24px;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin: 20px 0 0 20px;
}

.list-items_1-3 {
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/4e961e32d4464acda15f8d9fbbd535d5_mergeImage.png);
  height: 260px;
  margin-right: 40px;
  width: 270px;
  background: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/a72abf7e8cf44583b937908b0409d432_mergeImage.png);
  float: left;
}

.text-wrapper_5-3 {
  background-color: rgba(0, 0, 0, 0.3);
  height: 260px;
  width: 270px;
}

.text_18-3 {
  width: 48px;
  height: 24px;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  margin: 20px 0 0 20px;
}

.box_6 {
  background-color: rgba(240, 244, 255, 0.8);
  border-radius: 6px;
  width: 70px;
  height: 260px;
  border: 1px solid rgba(44, 107, 255, 1);
  margin-top: 210px;
  justify-content: flex-center;
}

.image-text_1 {
  width: 56px;
  height: 36px;
  margin: 14px 0 0 7px;
}

.group_3 {
  width: 14px;
  height: 16px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge9cd0da72a63380d6ba789cc3cf171eee56be5c68bea8df1cf0ca4a1b6239f12)
    100% no-repeat;
  background-size: 100% 100%;
  margin-left: 21px;
}

.text-group_2 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.image_1 {
  width: 60px;
  height: 1px;
  margin: 14px 0 0 4px;
}

.image-text_2 {
  width: 56px;
  height: 36px;
  margin: 14px 0 0 7px;
}

.thumbnail_4 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
}

.text-group_3 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.image_2 {
  width: 60px;
  height: 1px;
  margin: 14px 0 0 4px;
}

.image-text_3 {
  width: 56px;
  height: 36px;
  margin: 14px 0 0 7px;
}

.thumbnail_5 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
}

.text-group_4 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.image_3 {
  width: 60px;
  height: 1px;
  margin: 14px 0 0 4px;
}

.image-text_4 {
  width: 56px;
  height: 36px;
  margin: 15px 0 14px 7px;
}

.thumbnail_6 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
}

.text-group_5 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.box {
  margin-top: 50px;
}

.box_7 {
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/3e73fc186c524323b65a42c0d2003397_mergeImage.png);
  height: 400px;
  width: 100%;
  margin: 40px 0 61px 0;
}

.section_3 {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 400px;
  justify-content: flex-center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-group_6 {
  width: 40px;
  height: 44px;
  overflow: hidden;
}

.text_19 {
  width: 40px;
  height: 20px;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-bottom: 30px;
}

.text_20 {
  width: 40px;
  height: 14px;
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.text_21 {
  height: 14px;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.block_13 {
  background-color: rgba(240, 244, 255, 0.8);
  border-radius: 6px;
  z-index: 88;
  position: absolute;
  left: 1850px;
  top: 780px;
  width: 70px;
  height: 260px;
  border: 1px solid rgba(44, 107, 255, 1);
  justify-content: flex-center;
}

.image-text_8 {
  width: 56px;
  height: 36px;
  margin: 14px 0 0 7px;
}

.group_10 {
  width: 14px;
  height: 16px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng374037336bd4197a2ca6f396c4110ccfddcc9ae22c4c0aa2935009e619c2864e)
    100% no-repeat;
  background-size: 100% 100%;
  margin-left: 21px;
}

.text-group_8 {
  width: 56px;
  height: 14px;
  color: rgba(44, 107, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.image_7 {
  width: 60px;
  height: 1px;
  margin: 14px 0 0 4px;
}

.image-text_9 {
  width: 56px;
  height: 36px;
  margin: 14px 0 0 7px;
}

.thumbnail_4 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
}

.text-group_9 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.image_8 {
  width: 60px;
  height: 1px;
  margin: 14px 0 0 4px;
}

.image-text_10 {
  width: 56px;
  height: 36px;
  margin: 14px 0 0 7px;
}

.thumbnail_5 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
}

.text-group_10 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}

.image_9 {
  width: 60px;
  height: 1px;
  margin: 14px 0 0 4px;
}

.image-text_11 {
  width: 56px;
  height: 36px;
  margin: 15px 0 14px 7px;
}

.thumbnail_6 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
}

.text-group_11 {
  width: 56px;
  height: 14px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 6px;
}
</style>
  