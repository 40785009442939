<template>
  <commomNav :active="3" :columnList="columnList" type="2"></commomNav>
  <div class="main">
    <div class="banner">
      <img :src="banner" />
    </div>
    <div class="classical_case">
      <div class="title">
        {{ subjectName }}
      </div>
      <div class="classical_content">
        <div
          class="case_contain"
          v-for="(item, index) in classicalList"
          :key="index"
          @click="toPage('Smarthealthcare', item.id)"
        >
          <div class="image"><img :src="item.imgUrl" /></div>
          <div class="classical_title">
            <div>{{ item.title }}</div>
            <div class="arrow">
              <img
                class="normal"
                src="@/assets/img/home/箭头_向右-正常状态.png"
              />
              <img class="hover" src="@/assets/img/home/箭头_向右-选中.png" />
            </div>
          </div>
          <div class="content">
            <div class="date">
              <div class="year">{{ item.year }}</div>
              <div class="day">{{ item.day }}</div>
            </div>
            <div class="desc">
              {{ item.briefIntroduction }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <commonBottom
    :HardwareList="HardwareList"
    :softwareList="softwareList"
    :columnList="columnList"
  ></commonBottom>
</template>

<script>
import commomNav from "@/components/commonNav.vue";
import commonBottom from "@/components/commonBottom.vue";
import { carouselList, getColumn } from "@/api/api/office";
export default {
  components: { commomNav, commonBottom },
  setup() {},
  created() {
    if (!sessionStorage.getItem("column_zh") && sessionStorage.getItem("language") == "zh") {
      getColumn(sessionStorage.getItem("language")).then((response) => {
        this.columnList = this.handleTree(response, "id");
        sessionStorage.setItem('column_zh',JSON.stringify(this.columnList));
        this.setColumn();
        console.log(this.softwareList);
      });
    }else if (!sessionStorage.getItem("column_en") && sessionStorage.getItem("language") == "en") {
      getColumn(sessionStorage.getItem("language")).then((response) => {
        this.columnList = this.handleTree(response, "id");
        sessionStorage.setItem('column_en',JSON.stringify(this.columnList));
        this.setColumn();
        console.log(this.softwareList);
      });
    }else{
      if(sessionStorage.getItem("language") == "zh"){
        this.columnList=JSON.parse(sessionStorage.getItem('column_zh'));
      }
      if(sessionStorage.getItem("language") == "en"){
        this.columnList=JSON.parse(sessionStorage.getItem('column_en'));
      }
      console.log(this.columnList);
      this.setColumn();
    }
  },
  methods: {
    setColumn(){
        this.columnList[0].children.forEach((element) => {
          if (element.id == 3) {
            this.solutionList = element.children;
          }
          if (element.id == 4) {
            element.children.forEach((element2) => {
              if (element2.id == 9) {
                this.HardwareList = element2.children;
              }
              if (element2.id == 10) {
                this.softwareList = element2.children;
              }
            });
          }
          if (element.id == 5) {
            this.banner = element.imgUrl;
            this.classicalList = element.children;
            this.subjectName = element.subjectName;
            this.classicalList.forEach((element2) => {
              if (element2.issueDate) {
                var date = new Date(element2.issueDate);
                element2.year = date.getFullYear();
                var month = date.getMonth() + 1;
                if (month < 10) month = "0" + month;
                var day = date.getDate();
                if (day < 10) day = "0" + day;
                element2.day = month + "-" + day;
              }
            });
          }
          if (element.id == 6) {
            this.newsList = element.children;
          }
        });
    },
    handleTree(data, id, parentId, children, rootId) {
      id = id || "id";
      parentId = parentId || "parentId";
      children = children || "children";
      rootId = rootId || 0;
      //对源数据深度克隆
      const cloneData = JSON.parse(JSON.stringify(data));
      //循环所有项
      const treeData = cloneData.filter((father) => {
        let branchArr = cloneData.filter((child) => {
          //返回每一项的子级数组
          return father[id] === child[parentId];
        });
        branchArr.length > 0 ? (father.children = branchArr) : "";
        //返回第一层
        return father[parentId] === rootId;
      });
      return treeData != "" ? treeData : data;
    },
    toPage(page, id) {
      this.$router.push({
        path: page,
        query: { id: id },
      });
    },
  },

  data() {
    return {
      columnList: [{ children: [] }],
      solutionList: [], //解决方案
      HardwareList: [], //硬件产品
      softwareList: [], //软件产品
      classicalList: [], //经典案例
      newsList: [], //新闻资讯
      subjectName: null,
      banner: null,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.main {
  min-height: 100vh;
}
.classical_case {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .title {
    width: 1200px;
    font-size: 36px;
    color: #333333;
    text-align: center;
    margin: 60px 0 40px;
    position: relative;

    .more {
      font-size: 16px;
      color: #999999;
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 25px;

      img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
  }

  .classical_content {
    width: 1200px;
    display: flex;
    .image {
      overflow: hidden;
      height: 200px;
    }
    .case_contain {
      width: 47%;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin-bottom: 40px;

      .classical_title {
        font-size: 16px;
        color: #333333;
        display: flex;
        justify-content: space-between;
        margin: 20px 0;

        .arrow {
          padding-left: 5px;
          border-left: 1px solid transparent;
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
          }

          .hover {
            display: none;
          }
        }
      }

      .content {
        display: flex;

        .date {
          padding-right: 15px;
          border-right: 1px solid #999999;
          display: flex;
          flex-direction: column;
          align-items: center;

          .year {
            font-size: 18px;
            color: #666666;
          }

          .day {
            font-size: 14px;
            color: #999999;
          }
        }

        .desc {
          font-size: 14px;
          color: #666666;
          padding-left: 15px;
        }
      }
    }

    .case_contain:nth-child(odd) {
      margin-right: 1.5%;
    }

    .case_contain:nth-child(even) {
      margin-left: 1.5%;
    }

    .case_contain:hover {
      img {
        transition: 0.5s;
        transform: scale(1.1);
      }
      .classical_title {
        color: #2c6bff;
      }

      .arrow {
        border-left-color: #cccccc;

        .normal {
          display: none;
        }

        .hover {
          display: block;
        }
      }
    }
  }
}
</style>