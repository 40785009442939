<template>
  <commomNav :active="4" :columnList="columnList" type="2"></commomNav>
  <div class="main">
    <div class="banner">
      <img :src="banner" />
    </div>
    <div class="classical_case">
      <div class="title">
        {{ content.title }}
      </div>
      <div class="classical_content ck-content" v-html="content.content"></div>
    </div>
  </div>

  <commonBottom
    :HardwareList="HardwareList"
    :softwareList="softwareList"
    :columnList="columnList"
  ></commonBottom>
</template>

<script>
import commomNav from "@/components/commonNav.vue";
import commonBottom from "@/components/commonBottom.vue";
import { getColumnContent, getColumn } from "@/api/api/office";
export default {
  components: { commomNav, commonBottom },
  setup() {},
  created() {
    if (
      !sessionStorage.getItem("column_zh") &&
      sessionStorage.getItem("language") == "zh"
    ) {
      getColumn(sessionStorage.getItem("language")).then((response) => {
        this.columnList = this.handleTree(response, "id");
        sessionStorage.setItem("column_zh", JSON.stringify(this.columnList));
        this.setColumn();
        console.log(this.softwareList);
      });
    } else if (
      !sessionStorage.getItem("column_en") &&
      sessionStorage.getItem("language") == "en"
    ) {
      getColumn(sessionStorage.getItem("language")).then((response) => {
        this.columnList = this.handleTree(response, "id");
        sessionStorage.setItem("column_en", JSON.stringify(this.columnList));
        this.setColumn();
        console.log(this.softwareList);
      });
    } else {
      if (sessionStorage.getItem("language") == "zh") {
        this.columnList = JSON.parse(sessionStorage.getItem("column_zh"));
      }
      if (sessionStorage.getItem("language") == "en") {
        this.columnList = JSON.parse(sessionStorage.getItem("column_en"));
      }
      console.log(this.columnList);
      this.setColumn();
    }
    this.getContent(this.$route.query.id, sessionStorage.getItem("language"));
  },
  methods: {
    setColumn() {
      this.columnList[0].children.forEach((element) => {
        if (element.id == 3) {
          this.solutionList = element.children;
        }
        if (element.id == 4) {
          element.children.forEach((element2) => {
            if (element2.id == 9) {
              this.HardwareList = element2.children;
            }
            if (element2.id == 10) {
              this.softwareList = element2.children;
            }
          });
        }
        if (element.id == 5) {
          this.classicalList = element.children;
        }
        if (element.id == 6) {
          this.banner = element.imgUrl;
          this.newsList = element.children;
        }
      });
    },
    getContent(id, language) {
      getColumnContent(id, language).then((response) => {
        console.log(response);
        this.content = response;
      });
    },
    handleTree(data, id, parentId, children, rootId) {
      id = id || "id";
      parentId = parentId || "parentId";
      children = children || "children";
      rootId = rootId || 0;
      //对源数据深度克隆
      const cloneData = JSON.parse(JSON.stringify(data));
      //循环所有项
      const treeData = cloneData.filter((father) => {
        let branchArr = cloneData.filter((child) => {
          //返回每一项的子级数组
          return father[id] === child[parentId];
        });
        branchArr.length > 0 ? (father.children = branchArr) : "";
        //返回第一层
        return father[parentId] === rootId;
      });
      return treeData != "" ? treeData : data;
    },
  },

  data() {
    return {
      columnList: [{ children: [] }],
      solutionList: [], //解决方案
      HardwareList: [], //硬件产品
      softwareList: [], //软件产品
      classicalList: [], //经典案例
      newsList: [], //新闻资讯
      banner: null,
      content: { title: "", content: "" },
    };
  },
};
</script>
    
<style lang="scss" scoped>
.main {
  min-height: 100vh;
}
.classical_case {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
  .title {
    width: 1200px;
    font-size: 36px;
    color: #333333;
    text-align: center;
    margin: 60px 0 40px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .more {
      font-size: 16px;
      color: #999999;
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 25px;

      img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
  }

  .classical_content {
    width: 1200px;

    :deep img {
      width: auto;
      max-width: 100%;
    }
  }
}
</style>