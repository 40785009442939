<template>
  <commomNav :active="2" :columnList="columnList" type="2"></commomNav>
  <div class="banner">
    <img :src="banner" />
  </div>
  <div class="Hardware">
    <div class="title">
      {{ subjectName }}
    </div>
    <div class="Hardware_container">
      <template v-for="(item, index) in HardwareList" :key="index">
        <div class="Hardware_content" @click="toPage('Hardware', item.id)">
          <div class="image"><img :src="item.imgUrl" /></div>
          <div class="name">{{ item.subjectName }}</div>
          <div class="desc">{{ item.briefIntroduction }}</div>
          <div class="btn">
            <img
              class="normal"
              src="@/assets/img/home/箭头_向右-正常状态.png"
            />
            <img class="hover" src="@/assets/img/home/箭头_向右-选中(1).png" />
          </div>
        </div>
      </template>
    </div>
  </div>
  <commonBottom
    :HardwareList="HardwareList"
    :softwareList="softwareList"
    :columnList="columnList"
  ></commonBottom>
</template>

<script>
import commomNav from "@/components/commonNav.vue";
import commonBottom from "@/components/commonBottom.vue";
import { carouselList, getColumn } from "@/api/api/office";
export default {
  components: { commomNav, commonBottom },
  setup() {},
  created() {
    if (
      !sessionStorage.getItem("column_zh") &&
      sessionStorage.getItem("language") == "zh"
    ) {
      getColumn(sessionStorage.getItem("language")).then((response) => {
        this.columnList = this.handleTree(response, "id");
        sessionStorage.setItem("column_zh", JSON.stringify(this.columnList));
        this.setColumn();
        console.log(this.softwareList);
      });
    } else if (
      !sessionStorage.getItem("column_en") &&
      sessionStorage.getItem("language") == "en"
    ) {
      getColumn(sessionStorage.getItem("language")).then((response) => {
        this.columnList = this.handleTree(response, "id");
        sessionStorage.setItem("column_en", JSON.stringify(this.columnList));
        this.setColumn();
        console.log(this.softwareList);
      });
    } else {
      if (sessionStorage.getItem("language") == "zh") {
        this.columnList = JSON.parse(sessionStorage.getItem("column_zh"));
      }
      if (sessionStorage.getItem("language") == "en") {
        this.columnList = JSON.parse(sessionStorage.getItem("column_en"));
      }
      console.log(this.columnList);
      this.setColumn();
    }
  },
  methods: {
    setColumn() {
      this.columnList[0].children.forEach((element) => {
        if (element.id == 3) {
          this.solutionList = element.children;
        }
        if (element.id == 4) {
          element.children.forEach((element2) => {
            if (element2.id == 9) {
              this.banner = element2.imgUrl;
              this.subjectName = element2.subjectName;
              this.HardwareList = element2.children;
            }
            if (element2.id == 10) {
              this.softwareList = element2.children;
            }
          });
        }
        if (element.id == 5) {
          this.classicalList = element.children;
        }
        if (element.id == 6) {
          this.newsList = element.children;
        }
      });
    },
    handleTree(data, id, parentId, children, rootId) {
      id = id || "id";
      parentId = parentId || "parentId";
      children = children || "children";
      rootId = rootId || 0;
      //对源数据深度克隆
      const cloneData = JSON.parse(JSON.stringify(data));
      //循环所有项
      const treeData = cloneData.filter((father) => {
        let branchArr = cloneData.filter((child) => {
          //返回每一项的子级数组
          return father[id] === child[parentId];
        });
        branchArr.length > 0 ? (father.children = branchArr) : "";
        //返回第一层
        return father[parentId] === rootId;
      });
      return treeData != "" ? treeData : data;
    },
    toPage(page, id) {
      this.$router.push({
        path: page,
        query: { id: id },
      });
    },
  },

  data() {
    return {
      columnList: [{ children: [] }],
      solutionList: [], //解决方案
      HardwareList: [], //硬件产品
      softwareList: [], //软件产品
      classicalList: [], //经典案例
      newsList: [], //新闻资讯
      subjectName: null,
      banner: null,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.Hardware {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .title {
    width: 1200px;
    font-size: 36px;
    color: #333333;
    text-align: center;
    margin: 60px 0 40px;
    position: relative;

    .more {
      font-size: 16px;
      color: #999999;
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 25px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
    .more:hover {
      color: #2c6bff;
    }
  }

  .Hardware_container {
    width: 1240px;
    display: flex;
    flex-wrap: wrap;

    .Hardware_content {
      width: 32%;
      margin: 0 0.5% 20px;
      background: #ffffff;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 20px 25px;
      cursor: pointer;

      .image {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 275px;
        margin-bottom: 10px;
        overflow: hidden;
        img {
          max-height: 100%;
        }
      }

      .name {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .desc {
        font-size: 14px;
        color: #666666;
        text-align: center;
        line-height: 24px;
        margin-bottom: 20px;
      }

      .btn {
        width: 120px;
        height: 40px;
        background: #dedede;
        text-align: center;
        padding: 13px;

        img {
          width: 16px;
          height: 16px;
          display: inline-block;
        }

        .hover {
          display: none;
        }
      }
    }

    .Hardware_content:hover {
      img {
        transition: 0.5s;
        transform: scale(1.1);
      }
      .btn {
        background: #3c4250;

        .normal {
          display: none;
        }

        .hover {
          display: inline-block;
        }
      }
    }
  }
}
</style>