import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/assets/css/common.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons-vue' //全局图标


const app = createApp(App);
app.use(ElementPlus, {
    zIndex: 3000,
    size: 'large'
})
app.use(store)
app.use(router)
app.mount('#app')
Object.keys(Icons).forEach((key) => {
    app.component(key, Icons[key])
})